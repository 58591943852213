import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Hosting" />
    <h1>Monthly Website Packages</h1>
  </Layout>
)

export default IndexPage